export function getTodayDateRange() {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
    const endDate = startDate + 86400000; // Add one day in milliseconds
    return { startDate, endDate };
}

export function getYesterdayDateRange() {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).getTime();
    const endDate = startDate + 86400000; // Add one day in milliseconds
    return { startDate, endDate };
}

export function getThisWeekDateRange() {
    const today = new Date();
    const firstDayOfWeek = today.getDate() - today.getDay(); // Adjust to the first day of this week, assuming Sunday as the first day
    const startDate = new Date(today.getFullYear(), today.getMonth(), firstDayOfWeek).getTime();
    const endDate = startDate + (86400000 * 7); // Add seven days in milliseconds
    return { startDate, endDate };
}

export function getLastWeekDateRange() {
    const today = new Date();
    const dayOfWeek = today.getDay(); // Get current day of the week, 0 (Sunday) to 6 (Saturday)
    const endOfLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayOfWeek - 1);
    const startOfLastWeek = new Date(endOfLastWeek.getFullYear(), endOfLastWeek.getMonth(), endOfLastWeek.getDate() - 6);

    const startDate = startOfLastWeek.getTime();
    const endDate = endOfLastWeek.getTime() + 86400000; // Add one day in milliseconds to include the end date fully
    return { startDate, endDate };
}


export function getLast7DaysDateRange() {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).getTime();
    const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
    return { startDate, endDate };
}

export function getThisMonthDateRange() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const startDate = new Date(year, month, 1).getTime();
    const endDate = new Date(year, month + 1, 1).getTime();
    return { startDate, endDate };
}

export function getLastMonthDateRange() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const startDate = new Date(year, month - 1, 1).getTime();
    const endDate = new Date(year, month, 1).getTime();
    return { startDate, endDate };
}