<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { auth, db, functions } from "@/firebase/config.js";
import { getDocs, collection, orderBy, query, doc, updateDoc, setDoc, deleteDoc, limit, where, startAfter, documentId } from "firebase/firestore";
import { getTodayDateRange, getYesterdayDateRange, getThisWeekDateRange, getLastWeekDateRange, getLast7DaysDateRange, getThisMonthDateRange, getLastMonthDateRange } from "../helpers/date.js";
import PopUp from "./PopUp.vue";

const datePresets = [
    { label: 'Today', value: 'today', dateRangeFunction: getTodayDateRange },
    { label: 'Yesterday', value: 'yesterday', dateRangeFunction: getYesterdayDateRange },
    { label: 'This Week', value: 'thisWeek', dateRangeFunction: getThisWeekDateRange },
    { label: 'Last Week', value: 'lastWeek', dateRangeFunction: getLastWeekDateRange },
    { label: 'Last 7 Days', value: 'last7Days', dateRangeFunction: getLast7DaysDateRange },
    { label: 'This Month', value: 'thisMonth', dateRangeFunction: getThisMonthDateRange },
    { label: 'Last Month', value: 'lastMonth', dateRangeFunction: getLastMonthDateRange }
];

const reviewerFilter = [
    { label: 'CAAP Knowledge', value: 'caap', dataKey: 'caap_data' },
    { label: 'CAAP Practical', value: 'plus', dataKey: 'caap_plus_data' },
    { label: 'Entrance Exam', value: 'mtp', dataKey: 'mtp_data' },
];

const sortDirection = [
    { label: 'Ascending', value: 'asc' },
    { label: 'Descending', value: 'desc' },
];

const isWaiting = ref(false);
const isPopupOpen = ref(false);

/** Initialize the options value */
const options = ref({
    datePreset: datePresets[5].value, // default is this month
    reviewerFilter: reviewerFilter.map(e => e.value),
    sortDirection: sortDirection[1].value,
});

const filters = ref({
    endorserFilter: []
})

const entries = ref([])

const endorserCodes = computed(() => {
    return Array.from(new Set(entries.value.map(entry => entry.endorserCode).filter(code => code !== null)));
})

const fetchBuyers = async (reviewer) => {
    return new Promise(async (resolve, reject) => {

        const selectedPreset = datePresets.find(preset => preset.value === options.value.datePreset);
        const { startDate, endDate } = selectedPreset.dateRangeFunction();

        console.log(convertTimestampToDate(startDate))
        console.log(convertTimestampToDate(endDate))

        let userQuery = query(
            collection(db, "users"),
            orderBy(`${reviewer.dataKey}.purchase_date`, "desc"),
            where(`${reviewer.dataKey}.purchase_date`, ">=", startDate.toString()),
            where(`${reviewer.dataKey}.purchase_date`, "<", endDate.toString()),
            limit(100));
        try {
            const userQuerySnapshot = await getDocs(userQuery);
            const users = userQuerySnapshot.docs.map(doc => {
                let data = doc.data();
                return {
                    id: doc.id,
                    data: data,
                    purchaseType: reviewer.label,
                    purchaseDate: data[reviewer.dataKey].purchase_date,
                    originalPrice: data[reviewer.dataKey].originalPrice,
                    finalPrice: data[reviewer.dataKey].finalPrice,
                    refCode: data[reviewer.dataKey].gcash_refno,
                    couponCode: (data[reviewer.dataKey].couponCode) ? data[reviewer.dataKey].couponCode : null,
                    endorserCode: (data[reviewer.dataKey].endorserCode) ? data[reviewer.dataKey].endorserCode : null,
                }
            });
            console.log(users);
            resolve(users);
        } catch (error) {
            console.error(error)
            reject();
        }
    })
}

const initiateSearch = async () => {
    entries.value = [];
    console.log('initiateSearch fired')
    console.log(options.value)
    isWaiting.value = true;
    try {
        /**
         * Avoid using forEach. The forEach loop with asynchronous operations does not wait for all 
         * fetch operations to complete before it moves to the finally block. 
         * 
         * Using Promise.all() will ensure that all asynchronous tasks are completed before proceeding. 
         */

        const promises = options.value.reviewerFilter.map(async reviewer => {
            let reviewerObj = reviewerFilter.find(e => e.value === reviewer);
            let results = await fetchBuyers(reviewerObj);
            return results;
        });

        const allResults = await Promise.all(promises);
        entries.value = allResults.flat();
    } catch (error) {
        console.error(error);
    } finally {
        entries.value.sort((a, b) => {
            if (options.value.sortDirection == 'desc') {
                return b.purchaseDate - a.purchaseDate
            } else {
                return a.purchaseDate - b.purchaseDate
            }
        });


        isWaiting.value = false;
    }
}

watch(() => options, async (newValue) => {
    initiateSearch();

}, { deep: true });

onMounted(() => {
    initiateSearch();
})

const filteredEntries = computed(() => {
    if (filters.value.endorserFilter.length === 0) {
        return entries.value;
    } else {
        return entries.value.filter(entry => filters.value.endorserFilter.includes(entry.endorserCode));
    }
})


const activeCoupon = ref(null);

const handlePopupOpen = () => {
    isPopupOpen.value = true;
}


const convertTimestampToDate = (timestampInMilliseconds) => {
    if (timestampInMilliseconds) {
        const date = new Date(parseInt(timestampInMilliseconds))
        return date.toLocaleDateString() + " at " + date.toLocaleTimeString()
    } else {
        return 'NA' // returns NA if timestamp is null (i.e. for legacy users, date_purchased is null)
    }
}

const totalFunds = computed(() => {
    let result = entries.value.reduce((sum, item) => sum + parseInt(item.finalPrice), 0);
    let resultFormatted = result.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return resultFormatted;
})


const calculateCommission = (endorserCode) => {
    const commissionPerEntry = 300;
    const count = entries.value.filter(entry => entry.endorserCode === endorserCode).length;
    let commissionTotal = count * commissionPerEntry;
    let commissionFormatted = commissionTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return commissionFormatted;

}

</script>
<template>
    <div :class="[(isWaiting) ? 'pointer-events-none opacity-50' : '', 'p-8 h-screen overflow-scroll']">

        <!-- Filters -->
        <section>

            <div class="text-sm font-bold">
                List Options:
            </div>
            <div class="border border-gray-300 px-3 py-2 text-sm text-gray-500 flex space-x-6">
                <div>
                    <label for="date-preset">Date Preset:</label>
                    <select id="date-preset" v-model="options.datePreset" class="py-0 text-sm">
                        <option v-for="preset in datePresets" :key="preset.value" :value="preset.value">
                            {{ preset.label }}
                        </option>
                    </select>
                </div>

                <div>
                    <label for="sort-direction">Sort Direction:</label>
                    <select id="sort-direction" v-model="options.sortDirection" class="py-0 text-sm">
                        <option v-for="direction in sortDirection" :key="direction.value" :value="direction.value">
                            {{ direction.label }}
                        </option>
                    </select>   
                </div>
            </div>

            <div class="text-sm font-bold mt-4">
                Reviewer Filter:
            </div>
            <div class="border border-gray-300 px-3 py-2 whitespace-nowrap px-2 py-2 text-sm text-gray-500 flex space-x-6">
                <label v-for="filter in reviewerFilter" :key="filter.value">
                    <input type="checkbox" :value="filter.value" v-model="options.reviewerFilter"
                        :disabled="isWaiting" />
                    {{ filter.label }}
                </label>
            </div>

            <div class="text-sm font-bold mt-4">
                Endorser Filter:
            </div>

            <div class="border border-gray-300 px-3 py-2 whitespace-nowrap px-2 py-2 text-sm text-gray-500 flex space-x-6">
                <label v-for="endorserCode in endorserCodes" :key="`filter-${endorserCode}`" class="flex items-center">
                    <input type="checkbox" :value="endorserCode" v-model="filters.endorserFilter"
                        :disabled="isWaiting" class="mr-1" />
                    <!-- {{ endorserCode }} -->
                    <span>{{ endorserCode }}</span>
                    <span> : ₱{{ calculateCommission(endorserCode) }}</span>
                </label>
            </div>
            
        </section>

        <!-- Results -->
        <section>

            <div class="mt-4 flex justify-between font-bold">
                <div>Showing {{ filteredEntries.length }} entries</div>
                <div>Total: ₱{{ totalFunds }}</div>
            </div>

            <!-- <div>
                <ul class="text-xs divide-y divide-gray-300">
                    <li v-for="(entry, index) in entries" :key="`entry-${index}-${entry.id}`" class="py-4">
                        <div class="mb-2">
                            <strong>
                                {{ convertTimestampToDate(entry.purchaseDate) }}: {{ entry.data.email }}</strong> </div>
                        <div>
                            {{ entry.data }}
                        </div>
                    </li>
                </ul>
            </div> -->

            <div>

                <div class="mt-8 flow-root">
                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col"
                                            class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                            Email</th>
                                        <th scope="col"
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Reviewer</th>
                                        <th scope="col"
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Date</th>
                                        <th scope="col"
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Ref Code</th>
                                        <th scope="col"
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Final Price</th>
                                        <th scope="col"
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Coupon Code</th>
                                        <th scope="col"
                                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Endorser Code</th>
                                        <!-- <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0">
                                            <span class="sr-only">Edit</span>
                                        </th> -->
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for="(entry, index) in filteredEntries" :key="`${entry.id}-${index}`">
                                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                                            {{ entry.data.email }}
                                        </td>
                                        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                            {{ entry.purchaseType }}
                                        </td>
                                        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                            {{ convertTimestampToDate(entry.purchaseDate) }}
                                        </td>
                                        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                            {{ (entry.refCode == 'PURCHASED_VIA_ADMIN') ? 'ADMIN' : entry.refCode }}
                                        </td>
                                        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                            ₱{{ parseInt(entry.finalPrice).toLocaleString('en-US', {
        minimumFractionDigits: 2, maximumFractionDigits: 2
    }) }}
                                        </td>
                                        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                            {{ entry.couponCode }}
                                        </td>
                                        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                            <button @click="handlePopupOpen(entry.couponCode)"
                                                class="text-indigo-600 hover:text-indigo-900">
                                                {{ entry.endorserCode }}
                                            </button>
                                        </td>
                                        <!-- <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                            <a href="#" class="text-indigo-600 hover:text-indigo-900">Edit<span
                                                    class="sr-only">, {{ transaction.id }}</span></a>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <PopUp :open="isPopupOpen" @close="isPopupOpen = false" />

        </section>
    </div>
</template>

<style scoped>
label {
    margin-right: 10px;
}

input[type="date"] {
    margin-right: 10px;
}
</style>